import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'base',
    children: [
      {
        path: 'activity',
        component: () => import('@/views/activity/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'activity-main',
            component: () => import('@/views/activity/Main.vue'),
          },
          {
            path: ':id(\\d+)',
            component: () => import('@/views/activity/Board.vue'),
            children: [
              {
                path: 'info',
                name: 'activity-info',
                component: () => import('@/views/activity/Info.vue'),
              },
              {
                path: 'source',
                name: 'activity-source',
                component: () => import('@/views/activity/Source.vue'),
              },
              {
                path: 'data',
                name: 'activity-data',
                component: () => import('@/views/activity/Data.vue'),
              },
              {
                path: 'editor',
                name: 'activity-editor',
                component: () => import('@/views/activity/Editor.vue'),
              },
              {
                path: 'wecorp',
                name: 'activity-wecorp',
                component: () => import('@/views/activity/Wecorp.vue'),
              },
              {
                path: 'invite',
                name: 'activity-invite',
                component: () => import('@/views/activity/Invite.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'learn',
        name: 'learn',
        component: () => import('@/views/learn/Index.vue'),
        children: [
          {
            path: '',
            name: 'learn-main',
            component: () => import('@/views/learn/Main.vue'),
          },
        ],
      },
    ],
    component: () => import('@/views/Base.vue'),
    alias: ['/home'],
    beforeEnter: () => {
      // if (!document.getElementById('ai-script')) {
      //   const script = document.createElement('script');
      //   script.id = 'ai-script';
      //   script.async = true;
      //   script.defer = true;
      //   script.src =
      //     'http://121.40.93.67:8080/api/application/embed?protocol=http&host=121.40.93.67:8080&token=af3f3f5a29d2e6e6';
      //   document.head.appendChild(script);
      // }
    },
  },
  {
    path: '/learn/editor/:learnId(\\d+)',
    name: 'editor',
    component: () => import('@/views/editor/Index.vue'),
    children: [
      {
        path: '',
        redirect: (to: any) => {
          const learnId = to.params.learnId;
          return { name: 'editor-board', params: { learnId, contentId: 0 } };
        },
      },
      {
        path: 'board/:contentId(\\d+)',
        name: 'editor-board',
        component: () => import('@/views/editor/Board/index.vue'),
      },
    ],
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/NotFound.vue') },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
